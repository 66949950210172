import React from "react"
import { window } from "browser-monads"
import ReCAPTCHA from "react-google-recaptcha"

import RrLogo from "../assets/img/rapyuta_robotics_logo.png"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  ajax,
  API_CALL_STATUSES,
  getPathQueryParam,
  getServiceQueryParam,
} from "../utils"
import Progress from "../components/progress"
import CenteredContainer from "../components/CenteredContainer"
import FancyInput, { FancyCardHeader } from "../components/FancyInput"
import FancyLabel from "../components/FancyLabel"

const getErrorMessage = err => {
  switch (err && err.status ? err.status : "") {
    case 500:
      return "Cannot access service due to server error. Please try again later."
    default:
      return "Failed to change password"
  }
}

const { captchaSiteKey } = window

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      email: "",
      grecaptchaValue: "",
      showCaptchaMessage: false,
      resetStatus: API_CALL_STATUSES.IDLE,
      resetError: "",
    }

    this.grecaptchaRef = React.createRef()
  }

  onResetSubmit = e => {
    e.preventDefault()
    const { grecaptchaValue, email } = this.state

    if (!grecaptchaValue) {
      this.setState({
        showCaptchaMessage: true,
      })
      return null
    }

    this.setState({
      showCaptchaMessage: false,
      resetStatus: API_CALL_STATUSES.PROGRESS,
    })

    ajax({
      path: "user/forgotpassword",
      headers: { service: getServiceQueryParam(), path: getPathQueryParam() },
      type: "POST",
      data: {
        email,
        "g-recaptcha-response": grecaptchaValue,
        captcha: grecaptchaValue,
      },
      success: () => {
        this.setState({
          resetStatus: API_CALL_STATUSES.SUCCESS,
        })
      },
      error: err => {
        this.grecaptchaRef.current.reset()
        this.setState({
          grecaptchaValue: "",
          resetStatus: API_CALL_STATUSES.ERROR,
          resetError: getErrorMessage(err),
        })
      },
    })
  }

  onCaptchaChange = grecaptchaValue => {
    this.setState({
      grecaptchaValue,
      showCaptchaMessage: !grecaptchaValue,
    })
  }

  updateEmail = e => {
    this.setState({
      email: e.target.value,
    })
  }

  render() {
    const { resetStatus, showCaptchaMessage, resetError, email } = this.state
    return (
      <Layout bgimage stickyFooter dark>
        <SEO title="Forgot Password" />
        {resetStatus === API_CALL_STATUSES.PROGRESS && <Progress />}
        <CenteredContainer>
              
              
              {resetStatus === API_CALL_STATUSES.SUCCESS ? (
                <div id="success">
                  <FancyCardHeader title="Recover Account Password">
                  <small className="text-left">
                    <b>Email sent to your registered mail address. </b>
                    <br />Please check your email to reset your password 
                    <br /> 
                    <br />
                    Some mail servers may mark the email as spam. 
                      <br/>
                      Please also check your spam folder.
                  </small>
                  </FancyCardHeader>
                  
                </div>
              ) : (
                <form id="resetForm" onSubmit={this.onResetSubmit}>
                  <FancyCardHeader title="Recover Account Password">
                  <small className="text-left">
                    Enter your registered email address below. An email containing a reset password link will be sent to your registered address. 
                  </small>
                  </FancyCardHeader>
                  <div className="inputWrapper">
                    <FancyLabel>Email</FancyLabel>
                    <FancyInput
                      type="email"
                      value={email}
                      onChange={this.updateEmail}
                      required
                    />
                  </div>
                  <div id="captchaWrapper">
                    <ReCAPTCHA
                      ref={this.grecaptchaRef}
                      sitekey={captchaSiteKey}
                      onChange={this.onCaptchaChange}
                    />
                  </div>
                  {showCaptchaMessage && (
                    <p className="validation">
                      Captcha verification is mandatory. Please select the
                      checkbox above.
                    </p>
                  )}
                  {resetStatus === API_CALL_STATUSES.ERROR && (
                    <p id="error">{resetError}</p>
                  )}
                  <button
                    className="redBtn"
                    disabled={resetStatus === API_CALL_STATUSES.PROGRESS}
                  >
                    SEND RESET LINK
                  </button>
                </form>
              )}
        </CenteredContainer>
      </Layout>
    )
  }
}

export default ForgotPassword
